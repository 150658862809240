import {
  makeSubscribedSelector,
  makeOptedoutSelector,
  makePrepaidSubscribedSelector,
  makeProductPrepaidShipmentOptionsSelector
} from './selectors';

export const inStock = (state, ownProps) => (state.inStock || {})[(ownProps.product || {}).id];
export const eligible = (state, ownProps) => (state.autoshipEligible || {})[(ownProps.product || {}).id] || false;
export const autoshipByDefault = (state, ownProps) =>
  (state.autoshipByDefault || {})[(ownProps.product || {}).id] || false;

export const subscriptionEligible = (state, ownProps) =>
  ((state.offerId && state.offerId !== '0') || false) && eligible(state, ownProps) && inStock(state, ownProps);
export const eligibilityGroups = (state, ownProps) =>
  (state.eligibilityGroups || {})[(ownProps.product || {}).id] || null;

export const hasUpsellGroup = (state, ownProps) => {
  const groups = eligibilityGroups(state, ownProps);
  return groups === null || !!groups.find(it => it === 'upsell' || it === 'impulse_upsell');
};

export const prepaidEligible = (state, ownProps) => {
  const groups = eligibilityGroups(state, ownProps);
  return groups?.some(it => it === 'prepaid') || false;
};

export const subscribed = (state, ownProps) => makeSubscribedSelector(ownProps.product)(state);
export const optedout = (state, ownProps) => makeOptedoutSelector(ownProps.product)(state);
export const prepaidSubscribed = (state, ownProps) => makePrepaidSubscribedSelector(ownProps.product)(state);
export const hasPrepaidOptions = (state, ownProps) =>
  makeProductPrepaidShipmentOptionsSelector(ownProps.product.id)(state).length > 0;

export const hasUpcomingOrder = state => !!(state.nextUpcomingOrder && state.nextUpcomingOrder.public_id);

export const upcomingOrderContainsProduct = (state, ownProps) =>
  ((state.nextUpcomingOrder && state.nextUpcomingOrder.products) || []).includes((ownProps.product || {}).id);

/**
 * This conditions return true when an offer and product are eligible for impulse upsell
 *
 * @param {*} state
 * @param {*} ownProps
 */
export const upsellEligible = (state, ownProps) =>
  // don't show IU in cart offers
  !ownProps.offer?.isCart &&
  state.offerId &&
  state.offerId !== '0' &&
  state.auth &&
  inStock(state, ownProps) &&
  hasUpcomingOrder(state) &&
  hasUpsellGroup(state, ownProps);
/**
 * Determinates when an offer is eligible for regular, when product in stock and eligible but not upsell
 *
 * Upgrade from 2.13, this replaces slot="standard-tempalate" in og-offer
 * @param {*} state
 * @param {*} ownProps
 */
export const regularEligible = (state, ownProps) =>
  subscriptionEligible(state, ownProps) && !upsellEligible(state, ownProps);
